body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  background-color: rgb(26, 26, 26);
}

.status-header {
  font-size: 0.75rem;
  z-index: 1;
}

.wrapper {
  color: #fff;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  z-index: 2;
}

.background-video > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
}

@media (max-width: 800px) {
  .pending-video {
    object-position: -200px;
  }
}

.results {
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 2;
}

.results-message {
  font-size: 2rem;
  margin: 0;
  background-color: rgba(26, 26, 26, 0.65);
  padding: 1rem;
  border-radius: 15px;
}

.results-message h2 {
  margin: 0;
}

.previous-results {
  max-width: 80%;
  font-size: 0.75rem;
  margin: auto;
  line-height: 1.25rem;
}

a {
  color: #fff;
  text-decoration: none;
  padding-bottom: 1px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.397);
}
